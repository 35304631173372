.output-card-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .heading-container {
        display: flex;
        flex-direction: row;

        .info-container {
            width: 90%;

            .table-instruction {
                line-height: 16px;
            }
        }

        .toggle-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            width: 160px;
            height: 37px;
            background: #517ED0;
            border-radius: 10px;

            .white-outline {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                border-radius: 7px;
                width: 100px;
                height: 27px;
                
                .toggle-image {
                    height: 18px;
                    width: 18px;
                }
            }

            .toggle-text {
                font-family: Mulish;
                font-style: normal;
                font-weight: bolder;
                font-size: 12px;
                color: #517ED0;
                margin-left: 5px;
            }
        }
    }

    .risk-bar {
        max-width: 95%;
    }

    .high-risk-text {
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        text-align: center;
        text-decoration-line: underline;
        color: #517ED0;
    }

    .high-risk-modal {
        padding: 15px;
    }

    .output-container {
        flex-grow: 1;
        padding: 20px;
        display: flex;
        justify-content: center;
        text-align: center;

        .insect-loading {
            position: relative;
            height: 400px;
            display: flex;
            justify-content: center;

            .insect-img {
                position: relative;
                right: 0px;
                height: 400px;
            }
            .gradient-container {
                position: absolute;
                top: 75px;
                height: 250px;
                width: 150px;
            }
        }
        .progress-message{
            margin-top: 200px;
        }
        
    
        .model-empty-state {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
    
            color: #737B7D;
            padding: 50px;
            width: 360px;
    
            border: 1px solid #A0A4A8;
            box-sizing: border-box;
            border-radius: 15px;
        }
    }
    
    .tab-buttons-container {
        border-top: 1px solid #a0a4a8;
        display: flex;
        flex-direction: row;
        margin-top: 40px;

        .tab-button {
            width: 109px;
            height: 42px;
            border: none;
            background: #F1F3F4;
            border-radius: 0px 0px 10px 10px;
            font-family: Mulish;
            font-weight: 600;
            font-size: 13px;

            color: #7291CA;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            outline: none;

            .plus {
                height: 25px;
                width: 25px;
                margin-right: 3px;
            }
        }
        .active-tab {
            background: #FFFFFF;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            color: #86AA4D;
        }

        .tab-button:disabled {
            color: #737B7D;
        }
    }

    .download-container {
        display: flex;
        flex-direction: row;   
        justify-content: flex-end;
        margin-top: 25px;

        .download-image {
            margin-right: 5px;
            height: 18px;
            width: 26px;
        }

        .download-text {
            display: flex;
            flex-direction: row;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            margin-left: 15px;
            color: #86AA4D;
        }
    }
}
