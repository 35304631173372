@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

// general button styling
.button {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.model-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    height: fit-content;
    min-width: 100vw;
    min-height: 100vh;
    background-color: white;

    .model-content-container {
        .model-text-container {
            max-width: 1000px;
            width: 80vw;
            height: fit-content;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 25px;

            .model-title {
                font-family: Mulish;
                font-weight: bold;
                font-size: 28px;
                line-height: 35px;
                margin-bottom: 20px;
                margin-top: 15px;
                text-align: center;
            }
            .model-text {
                font-family: Mulish;
                font-size: 16px;
                line-height: 18px;
                .model-link {
                    text-decoration-line: underline;
                    color: #517ED0;
                }
            }
            .model-list {
                margin-left: 69px;
                padding: 0;
                font-family: Mulish;
                font-size: 14px;
                line-height: 15px;
                list-style-position: inside;
            }
            .model-bold {
                font-weight: bold;
            }
        }
        .container {
            display: flex;
            flex-direction: row;
        
            h1 {
                font-family: Mulish;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 16px;
                padding-bottom: 5px;
            }
        
            .input-category-title {
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #373F41;
                margin: 5px 0px 5px 0px;
            }
        
            .inputs-container {
                box-shadow: 0px 4px 8px rgba(37, 40, 43, 0.14);
                border-radius: 23px;
                padding: 20px 20px 20px 20px;
                margin: 20px;
                display: flex;
                flex-direction: row;
                width: 50%;
        
                .inputs-half {
                    flex-direction: column;

                    .autofill-instructions {
                        display: flex;
                        flex-direction: row;

                        .input-category-title {
                            width: 50%;
                        }
                    }

                    .checkbox-label {
                        padding-left: 10px;
                    }
        
                    .run-model-button-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        padding: 10px;
        
                        .run-model-button {
                            color: white;
                            cursor: pointer;
                            // Twitter Bootstrap "primary" color.
                            background-color: #337ab7;
                            background-image: none;
                            border: 1px solid transparent;
                            border-color: #2e6da4;
                            border-radius: 4px;
                            display: inline-block;
                            font-weight: normal;
                            padding: 8px 14px;
                            text-align: center;
                            white-space: nowrap;
                            vertical-align: middle;
                        }
        
                        .run-model-button:disabled {
                            background: #A0A4A8;
                            border: none;
                            color: #E4E4E4;
                        }
                    }

                }
    
            }
        
            .outputs-container {
                box-shadow: 0px 4px 8px rgba(37, 40, 43, 0.14);
                border-radius: 23px;
                padding: 30px;
                margin: 20px;
                display: flex;
                flex-direction: column;
                width: 50%;
        
            }
        
        }
    }
}
