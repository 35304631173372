.composite-model-card {
    background: #FFFFFF;
    border: 1px solid #787878;
    box-sizing: border-box;
    border-radius: 15px;
    margin: 15px;
    padding-bottom: 20px;

    display: flex;
    flex-direction: row;
    width: 90vw;

    .composite-text-container {
        display: flex;
        flex-direction: column;
        padding: 40px 15px 20px 64px;
        width: 40%;

        .composite-title {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
        }

        .composite-condition-label {
            font-family: Mulish;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            color: #000000;
        }

        .composite-condition-container {
            background: #F4F8FF;
            border: 2px solid #95B6F2;
            box-sizing: border-box;
            border-radius: 5px;
            margin-bottom: 15px;
            padding: 15px;

            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #517ED0;
        }

        .composite-text {
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #000000;
        }
    }

    .composite-graph-container {
        padding: 70px 30px 10px 35px;
        width: 60%;
        display: flex;
        flex-direction: column;

        .radio-buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .composite-radio {
                font-family: Mulish;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                padding: 15px;

                border-radius: 30px;
            }
        }

        
    }
}