#window-select-container{
    margin-top: 20px;
}

.autofill-container {
    font-family: Mulish;
    font-size: 16px;
    line-height: 20px;

    b {
        font-weight: bolder;
    }

    .autofill-input-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .autofill-dropdown {
            flex-grow: 1;
        }

        .open-autofill {
            width: 70%;
        }

        .closed-autofill {
            width: 30%;
        }
    }

    .all-trees-container {
        overflow-y: scroll;
        overflow-x: hidden;
        scroll-behavior: smooth;
        height: 140px;
        border: 1px solid #A0A4A8;
        border-top: 0;
        box-sizing: border-box;

        .letter-line {
            background: #E1E7F1;
            color: #737B7D;
            font-family: Mulish;
            font-style: italic;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;

            margin: 0px;
            padding: 3px 3px 3px 15px;
        }
        .tree-line {
            display: flex;
            flex-direction: row;
            padding-left: 40px;
            padding-top: 4px;

            .remove-button {
                border: none;
                width: 15px;
                height: 15px;
                margin-right: 10px;
                margin-top: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .lighter {
            background: #FFFFFF;
        }

        .darker {
            background: #FAFAFA;
        }
        
    }

    .tooltips {
        background-color: white !important;
        opacity: 1 !important;

        .multi-line {
            text-align: left !important;
        }
    }

    .add-button-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        bottom: 135px;

        .add-button {
            width: fit-content; 
            bottom: 60px;
            left: 150px; 
    
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            
            color: #E4E4E4;
            background: #86AA4D;
            border: none;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
            border-radius: 20px;
            padding: 5px 10px 5px 10px;
        }
    
        .add-button:disabled {
            background: #A0A4A8;
        }
    }
}
