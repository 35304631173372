@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.home-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;

  .home-flex-container {
    column-gap: 121px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    width: fit-content;

    .home-left-column {
      width: fit-content;
      font-family: Roboto;

      .home-title {
        font-weight: normal;
        font-size: 28px;
        line-height: 35px;
        color: #4f80bd;
        margin-bottom: 20px;
        margin-top: 15px;
      }

      .home-lead {
        color: black;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
        max-width: 574px;
      }

      .home-text {
        color: black;
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 15px;
        max-width: 574px;
      }

      .home-button-text-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 31px;
        font-size: 18px;
        line-height: 19px;
        color: #517ED0;
        text-align: left;

        .home-button-text-underline {
          text-decoration-line: underline;
        }
      }
    }

    .home-right-column {
      width: fit-content;

      .home-tree-logo {
        display: block;
        max-width: 150px;
        margin-right: 18px;
        margin-top: 66px;
      }

      .home-button-primary {
        background-color: #5bc0de; // Twitter Bootstrap "info" color.
        border: 1px solid transparent;
        border-color: #46b8da;
        border-radius: 6px;
        color: white;
        cursor: pointer;
        display: block;
        font-size: 18px;
        font-family: Mulish;
        line-height: 23px;
        margin: 20px auto 0px auto;
        padding: 12px 16px;
        width: fit-content;

        &:active, &:focus, &:hover {
          background-color: #31b0d5;
          border-color: #269abc;
        }

        &:active {
          outline: 0;
          box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }
      }

      .eula {
        font-size: 85%;
        margin: 10px auto 0px auto;
        max-width: 150px;
        text-align: center;
      }
    }
  }
}
