@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

.team-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  .team-content-container {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
    .team-title {
      font-family: Mulish;
      font-weight: bold;
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 20px;
      margin-top: 15px;
      width: fit-content;
    }
    .team-flex-container {
      justify-content: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 30px;
      column-gap: 90px;
      max-width: 1043px;
      .team-left-column {
        max-width: 559px;
        flex-basis: 559px;
        flex-grow: 1;
      }
      .team-right-column {
        max-width: 559px;
        flex-basis: 394px;
        flex-grow: 1;
      }
      .team-textbox {
        margin-bottom: 40px;
        .team-subtitle {
          font-family: Mulish;
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 24px;
        }
        .team-text {
          list-style-position: outside;
          margin: 0;
          padding: 0;
          font-family: Mulish;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
