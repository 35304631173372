@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');


.basic-info, .drop-down {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .tooltips {
        background-color: white !important;
        opacity: 1 !important;

        .multi-line {
            text-align: left !important;
        }
    }
    .genus-species-row {
        display: flex;
        flex-direction: row;

        .insect-species {
            flex-grow: 1;
            margin-left: 10px;

            .insect-species-input {
                min-width: 100%;
                font-size: 14px;
            }
        }

        .insect-genus {
            flex-grow: 1;
        }
    }

    .basic-user-input {
        margin-bottom: 5px;

        label {
            select, input {
                margin-left: 10px;
            }
        }
        .input-dropdown {
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #737B7D;
            padding: 7px;      
            background: #F4F5F4;
            border: 1px solid #A0A4A8;
            border-radius: 5px;

            max-width: 100%;
        }

        .checkbox-container-grid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            .checkbox-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 150px;
    
                input, label {
                    display: inline-block;
                    width: 30px;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: #737B7D;
                }
            }
        }
        

    }
}