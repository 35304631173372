.ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
    opacity: 1;
}
.sorted{
    opacity: 1;
}

.output-table {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    max-height: 650px;
    width: 100%;

    .tree-species {
        font-style: italic;
    }

    .shaded {
        background-color: #E8E8E8;
    }
    
    a {
        color: black;
    }

    .high-background {
        background-color: #5B5B5B;
        color: #FFFFFF;
    }

    .medium-background {
        background-color: #928E8E;
        color: #FFFFFF;
    }

    .low-background {
        background-color: #D7D7D7;
        color: #000000;
    }
}