@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.header-root {
  border-bottom: 6px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #f8f8f8 38.1953%, #3F4E57);
  border-radius: 0;
  width: 100vw;
  background-color: #f8f8f8;
  padding-left: 40px;
  padding-right: 500px;
}

.header-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 600px;
  align-items: center;
  margin: auto;

  .header-brand {
    padding-right: 20px;
  }

  .header-title-container {
    vertical-align: middle;
    display: inline-block;

    .header-title {
      color: #4f80bd;
      display: inline-block;
      font-family: "Calibri","Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }

  .header-item {
    padding: 15px 20px;
    width: fit-content;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    color: black;

    .header-dropdown {
      background-color: #f8f8f8;
      border: none;
      border-radius: 0px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-left: 25px;

      .header-dropdown-item {
        font-family: Roboto;
        font-size: 18px;
        line-height: 14px;
        color: black;
        padding: 10px 60px 10px 20px;
      }

      hr {
        border: 1px solid #C7C7C7;
        margin: 0px;
      }
    }
  }

  .header-item-selected {
    background-color: #e7e7e7;
    font-weight: bold;
  }

  .header-icon {
    vertical-align: top;
    height: 48px;
    margin: 1px 5px 1px 0px;
    padding-bottom: 3px;
  }
}
