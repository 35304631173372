@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

.publications-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;

  .publications-content-container {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;

    .publications-title {
      font-family: Mulish;
      font-weight: bold;
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 20px;
      margin-top: 15px;
      width: fit-content;
      align-self: center;
    }

    .publications-flex-container {
      justify-content: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 90px;
      max-width: 1043px;
    }
  }

  .refereed-publications-container, .published-datasets-container, .fact-sheet-container,
      .r-code-container, .phylogenetic-container, .shortest-divergence-container {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .refereed-publications-card, .published-datasets-card, .fact-sheet-card,
      .r-code-card, .phylogenetic-card, .shortest-divergence-card {
        background: #FFFFFF;
        border: 1px solid #787878;
        box-sizing: border-box;
        border-radius: 15px;
        margin: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
    
        display: flex;
        flex-direction: row;
        width: 80vw;
        
        .card-text-container {
            display: flex;
            flex-direction: column;
            padding: 20px 60px 20px 60px;
            width: 100%;
        }
    
        .card-title {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            color: #000000;
            align-self: center;
        }
    
        .card-text {
            font-family: Mulish;
            font-weight: normal;
            font-size: 16px;
            color: #000000;
        }
    }

  }
}
