@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

.resources-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  .resources-content-container {
    max-width: 1500px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 120px;
    padding-right: 120px;
    margin-top: 172px;
    gap: 40px;
    .resources-title {
      font-family: Mulish;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      margin-bottom: 21px;
    }
    .resources-subtitle {
      font-family: Mulish;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }
    .resources-card {
      border: 1px solid #787878;
      border-radius: 15px;
      padding-top: 35px;
      padding-bottom: 35px;
      padding-left: 83px;
      padding-right: 83px;
      .resources-card-title {
        font-family: Mulish;
        font-style: italic;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
      }
      .resources-card-text {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
      }
      .resources-card-list {
        margin-left: 54px;
        padding: 0;
        font-family: Mulish;
        font-size: 16px;
        line-height: 20px;
        list-style-position: inside;
        a {
          text-decoration-line: underline;
          color: #517ED0;
          word-break: break-all;
        }
      }
    }
  }
}
