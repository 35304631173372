@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
$signoutIcon: url('../../assets/icons/signout.svg');

.primary-button {
    font-family: Mulish;
    font-weight: 800;
    font-size: 16px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;

    background-color: #517ED0;
    border-radius: 30px;
    height: 50px;
    border: none;
    min-width: 160px;
    align-self: center;
    cursor: pointer;
}
.normal-text {
    font-family: Mulish;
    font-style: italic;
    font-size: 14px;
    text-align: center;
    color: #737B7D;
}

.modal {
    display: flex;
    width: 500px;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    padding-left: 61px;
    padding-right: 61px;
    padding-top: 30px;
    padding-bottom: 30px;
    .bold-text {
        font-family: Mulish;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        color: black;
    }
    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 25px;
    }
}

.root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    height: fit-content;
    background-color: #F2F7FA;
    .text-button {
        font-family: Mulish;
        font-size: 14px;
        text-decoration-line: underline;
        text-align: center;
        color: #517ED0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-top: 5px;
    }
    .left-text-button {
        font-family: Mulish;
        font-size: 14px;
        text-decoration-line: underline;
        text-align: left;
        color: #517ED0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: fit-content;
    }
    .scroll-container {
        margin: auto;
        max-height: 100%;
        width: fit-content;
        margin-left: 20px;
        margin-right: 20px;
        .right-container {
            display: flex;
            flex-direction: row-reverse;
            .signout-container {
                display: flex;
                flex-direction: row-reverse;
                margin-top: 14px;
                margin-bottom: 14px;
                margin-right: 10px;
                height: fit-content;
                width: fit-content;
                align-self: right;
                .signout-button {
                    background: transparent $signoutIcon no-repeat left;
                    padding-left: 32px;
                    height: 24px;
                    width: fit-content;
                    border: none;
                    font-family: Mulish;
                    font-weight: 800;
                    font-size: 16px;
                    text-decoration-line: underline;
                    color: #517ED0;
                    cursor: pointer;
                }
            }
        }
        .flex-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            width: fit-content;
            gap: 28px;
            padding-bottom: 28px;
            .card-column {
                display: flex;
                flex-direction: column;
                gap: 28px;
                width: fit-content;
            }
            .login-card {
                min-width: 585px;
                min-height: 585px;
                width: fit-content;
                height: fit-content;
                padding-top: 62px;
            }
            .fit-card {
                min-width: 330px;
                width: fit-content;
                height: fit-content;
                padding-top: 32px;
                padding-bottom: 32px;
            }
            .big-card {
                width: 793px;
                height: fit-content;
                padding-top: 32px;
                padding-bottom: 32px;
                padding-left: 40px;
                padding-right: 40px;
            }
            .card {
                .logo {
                    display: block;
                    width: 85px;
                    height: 85px;
                    margin-left: auto;
                    margin-right: auto;
                }
                display: flex;
                flex-direction: column;
                background: white;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                // margin-bottom: 28px;
                .card-name {
                    font-family: Mulish;
                    font-weight: 800;
                    font-size: 16px;
                    text-align: center;
                }
                .card-title-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 69px;
                    .card-title {
                        font-family: Mulish;
                        font-weight: 600;
                        font-size: 22px;
                        text-align: center;
                        margin: 0;
                    }
                    .card-subtitle {
                        font-family: Mulish;
                        font-weight: 600;
                        font-size: 18px;
                        text-align: center;
                        margin: 0;
                    }
                }
                .profile-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 30px;
                }
                form {
                    display: flex;
                    flex-direction: column;
                    max-width: 67%;
                    width: 295px;
                    height: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                    label {
                        display: block;
                        font-family: Mulish;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                        text-align: left;
                        padding-top: 10px;
                    }
                    .text-box {
                        display: block;
                        font-family: Mulish;
                        font-size: 16px;
                        line-height: 20px;
                        color: #737B7D;
                        background: #F4F5F4;
                        border: 1px solid #373F41;
                        height: 40px;
                        width: 100%;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                    .text-box-error {
                        border-color: 'crimson';
                    }
                    .profile-text-box-container {
                        display: flex;
                        flex-direction: row;
                        gap: 12px;
                    }
                }
                .bottom-flex-container {
                    height: 110px;
                    display: flex;
                    flex-direction: column-reverse;
                    .forgot-password-text-container {
                        width: 243px;
                        height: 55px;
                        align-self: center;
                    }
                }
                .error-text {
                    font-family: Mulish;
                    font-size: 14px;
                    text-align: center;
                    color: crimson;
                    height: 24px;
                }
                table {
                    table-layout: fixed;
                    align-self: center;
                    height: fit-content;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    border-collapse: collapse;
                    thead {
                        border-bottom: 2px solid #C7C7C7;
                        th {
                            font-family: Mulish;
                            color: #373F41;
                            font-weight: 600;
                            font-size: 14px;
                            text-align: left;
                            padding: 5px;
                        }
                        th:nth-child(1) {
                            width: 18%;
                        }
                        th:nth-child(2) {
                            width: 18%;
                        }
                        th:nth-child(3) {
                            width: 20%;
                        }
                        th:nth-child(4) {
                            width: 30%;
                        }
                        th:nth-child(5) {
                            width: 14%;
                        }
                    }
                    tbody {
                        td {
                            font-family: Mulish;
                            color: black;
                            font-size: 12px;
                            padding: 5px;
                            word-wrap: break-word;
                            line-height: 1.2;
                        }
                        tr:nth-child(even) {
                            background-color: #FAFAFA;
                            td:first-child {
                                border-top-left-radius: 10px;
                                border-bottom-left-radius: 10px;
                            }
                            td:last-child {
                                border-bottom-right-radius: 10px;
                                border-top-right-radius: 10px;
                            }
                        }
                    }
                    
                }
                .file-container {
                    display: flex;
                    flex-direction: row;
                    align-self: center;
                    gap: 7px;
                    width: fit-content;
                    padding-top: 16px;
                    .file-name {
                        font-family: Mulish;
                        font-weight: 400;
                        font-size: 16px;
                        white-space: nowrap;
                        align-self: center;
                    }
                }
                .date-select-container {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    padding-top: 20px;
                    margin-bottom: 5px;
                    justify-content: center;
                    .simple-dropdown {
                        color: #737B7D;
                        background: #F4F5F4;
                        border: 1px solid #373F41 !important;
                    }
                    .month-select {
                        width: 120px;
                    }
                    .year-select {
                        width: 80px;
                    }
                }
            }
        }        
    }
}
  


