@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');


.footer-root {
  margin-top: 88px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;

  .footer-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin: 20px auto;
    max-width: 1020px; // matches total width of logos including space between.
    padding: 0 20px;

    .footer-item {
      display: block;
      height: 45px;
      cursor: pointer;
    }

    hr {
      color: black;
      margin: 20px 0px;
      width: 100px;
    }

    .footer-text {
      font-family: Mulish;
      font-size: 14px;
      line-height: 20px;
      max-width: 605px; // even breaks with text; fits nicely with logo line.
      text-align: center;
    }

    .footer-dali-logo {
      vertical-align: middle;
    }
  }
}
