.modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;

    p {
        font-family: Mulish;
        font-style: normal;
        font-size: 14px;
    }
    
    .bold {
        font-weight: bold;
        color: #000000;
    }
    
    .red {
        font-style: italic;
        color: #FB4E4E;
    }

    .modal-user-input {
        width: 370px;
        margin-left: 70px;

        label, p {
            display: flex;
            flex-direction: column;
    
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
        
            color: #373F41;
        }

        .required-input-label {
            display: inline-block;
            margin-bottom: 2px;
        }

        input, .occupation-dropdown {
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #737B7D;
            padding: 7px;      
            background: #F4F5F4;
            border: 1px solid #A0A4A8;
            border-radius: 5px;
            width: 80%;
    
            margin-bottom: 15px;
        }
    }
    
    button {
        color: white;
        cursor: pointer;
        background-color: #337ab7; // Twitter Bootstrap "primary" color.
        background-image: none;
        border: 1px solid transparent;
        border-color: #2e6da4;
        border-radius: 4px;
        display: inline-block;
        font-weight: normal;
        margin-top: 20px;
        padding: 8px 14px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
    }

    button:disabled {
        background: #A0A4A8;
        border: none;
        color: #E4E4E4;
    }
}

