.science-container {
    display: flex;
    flex-direction: column;

    .tree-background-img {
        background-image: url('../../assets/tree-background.svg');
        background-repeat: repeat;
        position: absolute;
        height: 411px;
        width: 100vw;
    }

    .header-rectangle-background {
        background: rgba(40, 41, 46, 0.7);
        height: 408px;
        max-width: 60vw;
        position: relative;
        top: 37px;

        .header-text-container {
            padding-left: 20%;
            padding-top: 43px;

            .header-title {
                font-family: Mulish;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                color: #FFFFFF;
            }
    
            .header-about {
                font-family: Mulish;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                color: #FFFFFF;
            }
    
            .header-info {
                font-family: Mulish;
                font-style: italic;
                font-weight: normal;
                font-size: 16px;
                color: #FFFFFF;
                padding-top: 20px;
            }
        }
    }

    .header-rectangle-background:after {
        content: "";
        position: absolute;
        top: 0;
        left: 60vw;
        width: 0; 
        height: 0; 
        border-top: 408px solid rgba(40, 41, 46, 0.7); 
        border-right: 274px solid transparent; 
    }

    .what-is-model-container {
        display: flex;
        flex-direction: column;
        padding-top: 110px;

        .what-is-model-heading-background {
            background: #93AC6B;
            width: 422px;
            height: 61px;
            position: relative;

            .what-is-model-heading {
                padding-left: 210px;
            }
        }

        .what-is-model-heading-background:after {
            content: "";
            position: absolute;
            top: 0;
            left: 422px;
            border-top: 61px solid #93AC6B; 
            border-right: 42px solid transparent; 
        }

        .what-is-model-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
        }
    }

    .hypothesis-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 110px;

        .hypothesis-heading-background {
            background: #93AC6B;
            width: 560px;
            height: 61px;
            position: relative;

            .hypothesis-heading {
                padding-left: 50px;
            }
        }

        .hypothesis-heading-background:before {
            content: "";
            position: absolute;
            top: 0;
            right: 560px;
            border-top: 61px solid #93AC6B; 
            border-left: 42px solid transparent; 
        }

        .hypothesis-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .small-animations-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
                max-width: 50vw;
                min-width: 500px;
                padding-left: 10%;
                padding-top: 20px;

                .animation-item {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    margin-right: 30px;
                }

                .animation-middle-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    margin-bottom: 5px;
                }
            }

            .hypothesis-content-container {
                display: flex;
                flex-direction: column;
                max-width: 40%;
                padding-right: 15vw;
                padding-top: 15px;
            }
        }
    }

    .variables-container {
        display: flex;
        flex-direction: column;
        padding-top: 150px;

        .variables-heading-background {
            background: #93AC6B;
            width: 521px;
            height: 61px;
            position: relative;

            .variables-heading {
                padding-left: 200px;
            }
        }

        .variables-heading-background:after {
            content: "";
            position: absolute;
            top: 0;
            left: 521px;
            border-top: 61px solid #93AC6B; 
            border-right: 42px solid transparent; 
        }

        .variables-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
            padding-top: 15px;
            padding-left: 25px;
        }
    }

    .final-model-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 200px 50px 0px 50px;
        background: #FAFAFA;

        .final-model-diagram {
            height: auto;
            width: 70%;
        }

        .phylogeny-container {
            width: 80%;
            background: #FFFFFF;
            border: 1px solid #787878;
            box-sizing: border-box;
            border-radius: 15px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px;
            
            .phylogeny-diagram-container {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                flex-wrap: wrap;

                .phylogeny-img {
                    width: 500px;
                    height: 400px;
                }
            }
        }
    }

    .final-model-container:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        border-left: 50vw solid transparent;
        border-right: 50vw solid transparent;
        border-top: 130px solid #ffffff;
    }

    .final-model-container:after {
        content: "";
        position: absolute;
        bottom: -130px;
        left: 0px;
        border-left: 50vw solid transparent;
        border-right: 50vw solid transparent;
        border-top: 130px solid #FAFAFA;
    }

    .deep-dive-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 200px 50px 0px 50px;

        .deep-dive-animation {
            width: 850px;
            height: 600px;
        }

        .deep-dive-img {
            width: 850px;
            height: 600px;
        }
    }

    .deep-dive-container:after {
        content: "";
        position: absolute;
        bottom: -130px;
        left: 0px;
        border-left: 50vw solid transparent;
        border-right: 50vw solid transparent;
        border-top: 130px solid #ffffff;
    }

    .submodel-container {
        padding-top: 200px;
        background: #F4F5F4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .math-model-container {
        padding-top: 50px;
        padding-bottom: 50px;
        background: #F4F5F4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .math-model-card {
            background: #FFFFFF;
            border: 1px solid #787878;
            box-sizing: border-box;
            border-radius: 15px;
            margin: 15px;
            padding-bottom: 20px;
        
            display: flex;
            flex-direction: row;
            width: 90vw;
        }

        .math-model-text-container {
            display: flex;
            flex-direction: column;
            padding: 40px 15px 20px 64px;
            width: 40%;
        }

        .math-model-title {
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
        }

        .math-model-text {
            font-family: Mulish;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            color: #000000;
        }

        .math-model-equation-container {
            padding: 70px 30px 10px 35px;
            width: 60%;
            display: flex;
            flex-direction: column;

            img {
                width: 550px;
            }
        }
    }

    .link {
        text-decoration-line: underline;
        color: #517ED0;
    }
}

.subsection-heading {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    padding-top: 19px;
}

.final-model-heading {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

.subsection-content {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
    padding: 15px;
    max-width: 400px;
}
